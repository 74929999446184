.Email{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}
.left-e{
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
}
.left-e>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
}
.left-e>div{
    display: flex;
    gap: 1rem;
}
.right-e{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    right: 8rem;
}
.Email-container{
    display: flex;
    gap: 3rem;
    background-color: grey;
    padding: 1.5rem 1.5rem;
}
.Email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: rgb(0, 0, 0);
    font-size: 0.9rem;
    font-weight: bold;
}
::placeholder{
    color: var(--orange);
    text-transform: capitalize;
    font-size: 1rem;
}
@media screen and (max-width:768px) {
    .Email{
        flex-direction: column;
        gap: 1rem;
    }
    .left-e{
        font-size: x-large;
        flex-direction: column;
    }
    .right-e{
        padding: 2rem;
    }
}